import React from 'react';
import './App.css';
import { Container, Box, Typography } from '@mui/material';
import { SearchUI } from './components/General/SearchUI';
import { CheatSheetButton } from './components/General/CheatSheetButton';

function App() {

  const baseurl = process.env.REACT_APP_BACKEND_API_URL;

  let mode = "config";

  return (
    <Container>
      	<Box m = {5} ml = {2} width = "100%">
			<Box display = "flex" flexDirection = "row" justifyContent = "space-between" width = "100%">
				<Box>
					<Typography variant="h5">
						Almende Project Database Searcher
					</Typography>
				</Box>
				<Box>
					<CheatSheetButton/>
				</Box>
			</Box>
		</Box>
      	<Box display = "flex" flexDirection = "column" alignItems = "center" mt = {2}>
			<SearchUI
				baseurl = {baseurl}
			/>
		</Box>
    </Container>
  );
}

export default App;
