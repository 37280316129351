import React, { useState, useCallback, memo } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const SearchTextFieldForm = memo(({value, name, label, required, readOnly, autoFocus, onChange}) => {

    const useStyles = makeStyles({
    });
    const classes = useStyles();

    const [display, setDisplay] = useState(value);

    const handleChange = useCallback((e) => {
        console.log(e.target.value);
        setDisplay(e.target.value);
    }, [setDisplay]);

    const handleEnter = useCallback((e) => {
        if (e.key === 'Enter') {
            console.log(display);
            onChange(display);
        }
    }, [display, onChange]);
    
    return (
        <TextField
            required = {required}
            id = {"outlined-".concat(name)}
            label = {label}
            value = {display}
            className = {classes.textField}
            onChange = {handleChange}
            onKeyPress = {handleEnter}
            InputProps = {
                {
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                    ),
                }
            }
            inputProps = {{readOnly: readOnly}}
            autoFocus = {autoFocus}
        />
    );
  });