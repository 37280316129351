import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Container, Typography, Paper, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
var _ = require('lodash');

export const Details = ({project}) => {

    const useStyles = makeStyles({
        button: {
            textTransform: "none"
        },
        unclickable: {
            pointerEvents: "none"
        }
    });

    const classes = useStyles();

    let keys = Object.keys(project);

    return (
    <Container>
        <Box>
            <Box mb = {1}>
                <Typography>
                    The project has the following properties:
                </Typography>
            </Box>
            <Paper>
                <Box display = "flex" flexDirection = "column">
                    {keys.map((field, i) => (
                        (typeof project[field] === 'object' && project[field] !== null)?
                        <Accordion key = {"field-" + i.toString()}>
                            <AccordionSummary expandIcon = {<ExpandMoreIcon />}>
                                {field}
                            </AccordionSummary>
                            <AccordionDetails>
                                {Array.isArray(project[field])?
                                    <Box>
                                        {(typeof project[field][0] === 'string')?
                                            <Typography>
                                                {project[field].join(', ')}
                                            </Typography>:
                                            <Box>
                                                {project[field].map((item, j) => (
                                                    <Typography key = {j.toString()}>
                                                        {Object.keys(item).map((key, k) => (
                                                            key + ': ' + item[key] + ((k < project[field].length - 1)? ', ': '')
                                                        ))}
                                                    </Typography>
                                                ))}
                                            </Box>
                                        }
                                    </Box>:
                                    <Box display = "flex" flexDirection = "column">
                                        {Object.keys(project[field]).map(key => (
                                            <Typography key = {key}>
                                                {key}: {project[field][key]}
                                            </Typography>
                                        ))}
                                    </Box>
                                }
                            </AccordionDetails>
                        </Accordion>:
                        <Accordion key = {"field-" + i.toString()} className={classes.unclickable}>
                            <AccordionSummary>
                                {field}: {(project[field] !== null)? project[field]: "N/A"}
                            </AccordionSummary>
                        </Accordion>
                    ))}
                </Box>
            </Paper>
        </Box>
    </Container>
  );
  }