import React, { useState, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Box, Button } from '@mui/material';
import { TextFieldForm } from './TextFieldForm';
var _ = require('lodash');

export const QueryUI = ({ onSubmit }) => {

    const [query, setQuery] = useState('');

    const useStyles = makeStyles({
        button: {
            textTransform: "none"
        }
    });
    const classes = useStyles();

    const onClick = useCallback(val => {
        onSubmit(query);
    }, [query, onSubmit]);

    const onEnter = useCallback(val => {
        onSubmit(val);
    }, [onSubmit]);

    const onSearch = useCallback(val => {
        setQuery(val);
    }, [setQuery]);

    console.log(query);

    return (
        <Container>
            <Box display = "flex" flexDirection = "column" justifyContent = "flex-start" alignItems = "flex-start" p = {1}>
                <TextFieldForm
                    value = {query}
                    name = {"query-text"}
                    label = "SQL Query Text"
                    helperText = 'Be mindful that this is a PostgreSQL database, wherefore the user needs to use ""s around column names with capital letters.'
                    required = {true}
                    fullWidth = {true}
                    useEnter = {true}
                    onEnter = {onEnter}
                    onChange = {onSearch}
                />
                <Box p = {1} display = "flex" flexDirection = "row" justifyContent = "flex-end">
                    <Button component="span" onClick = {onClick}>
                        Submit Query
                    </Button>
                </Box>
            </Box>
        </Container>
      );
  }

