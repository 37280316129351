import React, { useState, useCallback, useEffect, memo } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import debounce from 'lodash.debounce';

const areEqual = (prevProps, nextProps) => {
    const keys = Object.keys(prevProps);
    for (var i = 0; i < keys.length; i ++) {
        if (prevProps[keys[i]] !== nextProps[keys[i]]) {
            return false
        }
    }
    return true
};

export const TextFieldForm = memo(({value, name, label, placeholder, required, readOnly, helperText, marker, fullWidth, useEnter, onEnter, onChange}) => {

    const useStyles = makeStyles({
    });
    const classes = useStyles();

    const [display, setDisplay] = useState(value);

    useEffect(() => {
        if (display !== value) {
            setDisplay(value);
        };
    }, [name, marker]); // Necessary for the case where, in ObjectForm, a field is removed, and another field name inherits its value.

    const debounceFunction = useCallback((val) => {
        onChange(val);
    }, [onChange]);

    const onChangeDebounced = useCallback(debounce(debounceFunction, 300));

    const handleChange = useCallback((e) => {
        setDisplay(e.target.value);
        onChangeDebounced(e.target.value);
    }, [setDisplay]); // Not adding onChangeDebounced here, as on every rerender, it makes a new function.

    const handleEnter = useCallback((e) => { // Now the debounces are not necessary anymore.
        if (e.key === 'Enter') {
            onEnter(display);
        }
    }, [display, onChange]);

    // const handleChange = useCallback((e) => {
    //     console.log(e.target.value);
    //     setDisplay(e.target.value);
    //     onChange(e.target.value);
    // }, [setDisplay, onChange]);

    return (
        <TextField
            required = {required}
            id = {"outlined-".concat(name)}
            label = {label}
            value = {display}
            className = {classes.textField}
            helperText = {helperText}
            onChange = {handleChange}
            onKeyPress = {useEnter? handleEnter: null}
            margin = "normal" // Is this necessary?
            // variant = "filled"
            placeholder = {placeholder}
            fullWidth = {fullWidth === true}
            // If we want to use icons, find a way to do this more nicely, and make sure to include SearchTextFieldForm in this component as well then.
            // InputProps = {
            //     (icon === "Search")?
            //     {
            //         startAdornment: (
            //             <InputAdornment position="start">
            //                 <SearchIcon/>
            //             </InputAdornment>
            //         ),
            //     }:
            //     {}
            // }
            inputProps = {{readOnly: readOnly}}
        />
    );
}, areEqual);