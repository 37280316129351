import React from 'react';
import { Container, Box } from '@mui/material';
import { ItemList } from './ItemList';
var _ = require('lodash');

function segregateProjects(projects) {
    let keylists = [];
    let lists = [];
    for (let result of projects) {
        let processed = false;
        let keys = Object.keys(result);
        for (let [i, keylist] of keylists.entries()) {
            if (keys.every(k => (keylist.includes(k)) && keylist.every(k => keys.includes(k)))) {
                if (!result.hasOwnProperty('id')) {
                    result['id'] = lists[i].length;
                }
                lists[i].push(result);
                processed = true;
            }
        }
        if (processed === false) {
            keylists.push(keys);
            if (!result.hasOwnProperty('id')) {
                result['id'] = 0;
            }
            lists.push([result]);
        }
    }
    console.log(lists)
    return lists
}

export const ItemLists = ({projects}) => {

    return (
        <Container>
            {segregateProjects(projects).map((elements, i) => (
                <Box pt = {2} key = {i}>
                    <ItemList
                        list = {elements}
                    />
                </Box>
            ))}
        </Container>
    );
};

