import React, { useState, useCallback, Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Box, Button, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions } from '@mui/material';
import { QueryUI } from './QueryUI';
import { ItemLists } from './ItemLists';
import { ItemList } from './ItemList';
import CircleLoader from 'react-spinners/CircleLoader';
import LoadingOverlay from 'react-loading-overlay';
var _ = require('lodash');

function insertIds(projects) {
    let newprojects = _.cloneDeep(projects);
    let count = 0;
    for (let [i, result] of projects.entries()) {
        if (!result.hasOwnProperty('id')) {
            newprojects[i]['id'] = count;
            count += 1;
        }
    }

    return newprojects
}

export const SearchUI = ({ baseurl }) => {;

    const [state, setState] = useState({
        active: false,
        projects: {}
    });
    const [error, setError] = useState({
        open: false,
        code: 200,
        message: '',
    });

    const useStyles = makeStyles({
        button: {
            textTransform: "none"
        }
    });
    const classes = useStyles();

    const queryDatabase = useCallback((query) => {
        state.active = true;
        setState({...state});
        fetch(baseurl + 'projects/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({query: query})
        }).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    console.log(data);
                    state.projects = data;
                    state.active = false;
                    setState(Object.assign({}, state))
                })
            }
            else {
                response.json().then(data => {
                    setError({
                        open: true,
                        code: response.status,
                        message: data['message']
                    });
                    state.active = false;
                    setState({...state});
                    console.log(data['message']);
                })
            }
        })
    }, [state, setState, baseurl]);

    const retrieveDatabase = useCallback(() => {
        state.active = true;
        setState({...state});
        fetch(baseurl + 'projects/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    console.log(data);
                    state.projects = data;
                    state.active = false;
                    setState(Object.assign({}, state))
                })
            }
            else {
                response.json().then(data => {
                    setError({
                        open: true,
                        code: response.status,
                        message: data['message']
                    });
                    state.active = false;
                    setState({...state});
                    console.log(data['message']);
                })
            }
        })
    }, [state, setState, baseurl]);

    const onCloseError = useCallback(() => {
        setError({
            open: false,
            code: error.code,
            message: error.message
        });
    }, []);

    // TODO: Add reinitialize database button.

    return (
        <Container>
            <Button onClick = {retrieveDatabase}>
                Retrieve entire database
            </Button>
            <QueryUI
                onSubmit = {queryDatabase}
            />
            <LoadingOverlay
                active = {state.active}
                styles={{
                    overlay: (base) => ({
                    ...base,
                    background: 'rgba(255, 255, 255, 0.8)' // Changes the color of the background.
                    })
                }}
                spinner = {<CircleLoader color = "navy"/>} // colors "white", "silver", "aqua", "teal", and "blue" are fitting for the grey background. Navy fits with the EFPF logo with a white background.
                // text = "Loading content..."
            >
                <Box width = "100%">
                    {Array.isArray(state.projects)?
                        <Fragment>
                            <ItemLists
                                projects = {state.projects}
                            />
                        </Fragment>:
                        <Fragment>
                            {Object.keys(state.projects).map((key, i) =>
                                <Box py = {1}>
                                    <ItemList
                                        header = {key}
                                        list = {insertIds(state.projects[key])}
                                    />
                                </Box>
                            )}
                        </Fragment>
                    }
                </Box>
            </LoadingOverlay>
            <Dialog open={error.open} onClose={onCloseError} aria-labelledby="error-dialog" maxWidth="md">
                <DialogTitle id="error-dialog-title">{error.code.toString().concat(' Error')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {error.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button component="span" className={classes.button} onClick = {onCloseError}>
                    Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
      );
  }

