import React, { useState } from 'react';
import { Box, Container, Typography, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip } from '@mui/material';
import HelpOutline from '@mui/icons-material/HelpOutline'
var _ = require('lodash');

export const CheatSheetButton = () => {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(!open);
    }

    return (
    <Container>
        <Box>
            <Tooltip title = "Cheat Sheet">
                <IconButton onClick = {handleOpen}>
                    <HelpOutline/>
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={() => handleOpen()} aria-labelledby="cheatsheet-dialog" maxWidth="md">
                <DialogTitle id="cheatsheet-dialog-title">{"Database Cheat Sheet"}</DialogTitle>
                <DialogContent>
                    <Box display = "flex" flexDirection = "column" p = {1}>
                        <Typography>
                            The available databases are h2020, fp7, interreg, rvo, itea, and aal.
                        </Typography>
                        <Typography>
                            Each database has an internal id for the SQL database. For custom queries, all results also have an id to be able to display it in a table.
                        </Typography>
                        <Box pt = {1}>
                            <Typography>
                                The h2020 and fp7 databases have the following fields:
                            </Typography>
                            <Typography>
                                rcn, acronym, status, programme, topics, frameworkProgramme, title, startDate, endDate, projectUrl, objective, totalCOst, ecMaxContribution, call, fundingScheme, coordinator, coordinatorCountry, participants, participantCountries, and subjects
                            </Typography>
                        </Box>
                        <Box pt = {1}>
                            <Typography>
                                The interreg database has the following fields:
                            </Typography>
                            <Typography>
                                index, beneficiaryName, operationName, approved, operationSummary, operationStart, operationEnd, totalExpenditure, cofinancingRate, postalCode, country, category, lastUpdateDate
                            </Typography>
                        </Box>
                        <Box pt = {1}>
                            <Typography>
                                The rvo database has the following fields:
                            </Typography>
                            <Typography>
                                nid, titel, allTerms, berichtTekst, voorbeeldWeergave, pad, updateDate, budget, status, jaar, seizoen, projectNummer, aanvrager, projectPartners, geolocatie, downloads
                            </Typography>
                        </Box>
                        <Box pt = {1}>
                            <Typography>
                                The aal database has the following fields:
                            </Typography>
                            <Typography>
                                name, description, objectives, expected, partners, projectName, website, coordinator, duration, startingDate, totalBudget, publicContribution
                            </Typography>
                        </Box>
                        <Box py = {1}>
                            <Typography>
                                The itea database has the following fields:
                            </Typography>
                            <Typography>
                                name, call, challenge, clusters, description, projectLeader, countries, partners
                            </Typography>
                        </Box>
                        <Typography>
                            For further information, please retrieve all databases and browse to your liking to get an understanding of the individual fields and what information they portray.
                        </Typography>
                        <Typography>
                            Keep in mind: most database values are strings, but they can be integers or floats if applicable. Currently, the database does not support JSON objects or arrays, so those are stringified, but that will be properly implemented in the future.
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button component="span" onClick = {() => handleOpen()}>
                    Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    </Container>
  );
  }