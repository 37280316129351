import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Button, Box, Paper, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Details } from './Details';
import { SearchTextFieldForm } from './SearchTextFieldForm';
// import { FilterAutoComplete } from './FilterAutoComplete';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
var _ = require('lodash');

// function formatFilters(filters) {
//     var tempFilters = [];
//     for (var i = 0; i < filters.length; i ++) {
//         tempFilters.push({columnName: "countries", value: filters[i]}) //TODO: columnName should be "tags" once that has been implemented in the backend as well.
//     }
//     console.log(tempFilters);
//     return tempFilters
// }

export const ItemList = ({header, list}) => {

    const [dialog, setDialog] = useState({
        open: false,
        data: {}
    });

    const [state, setState] = useState({
        search: '',
        list: list,
        count: 0
    })

    useEffect(() => {
        setState({list: list, count: 0});
    }, [list, setState]); // Necessary for the case where, in ObjectForm, a field is removed, and another field name inherits its value.

    const useStyles = makeStyles({
        button: {
            textTransform: "none"
        }
    });
    const classes = useStyles();

    let keys = Object.keys(list[0]);

    const handleSearch = useCallback((val) => {
        console.log(val);
        let newlist = [];
        if (val === '') {
            newlist = _.cloneDeep(list);
        }
        else {
            for (let item of list) {
                if (JSON.stringify(item).toLocaleLowerCase().includes(val.toLocaleLowerCase())) {
                    newlist.push(item);
                }
            };
        }
        setState({list: newlist, count: 1, search: val});
    }, [list, setState]);

    function handleOpenDialog(row) {
        setDialog({
            open: true,
            data: row
        });
    }

    function handleCloseDialog() {
        dialog.open = false;
        setDialog({...dialog});
    }

    const renderDetailsButton = (params) => {
        let row = params.row;

        return (
            <Box>
                <IconButton onClick={() => handleOpenDialog(row)}>
                    <DescriptionOutlinedIcon/>
                </IconButton>
            </Box>
        )
    }

    function formatColumns(keys) {
        let columns = [{
            field: '',
            headerName: 'Details',
            width: 160,
            renderCell: renderDetailsButton
        }];
        for (let key of keys) {
            columns.push({field: key, headerName: key, width: 160});
        }
        return columns
    }

    let columns = formatColumns(keys);

    const Toolbar = () => {
        return (
            <GridToolbarContainer>
                <Box display = "flex" flexDirection = "row" justifyContent = "space-between" py = {1} width = "100%">
                    <Box display = "flex" flexDirection = "column" justifyContent = "center" pl = {2}>
                        {
                            (header !== null && typeof header === "string")?
                            <Typography>
                                {header}
                            </Typography>:
                            null
                        }
                    </Box>
                    <Box>
                        <SearchTextFieldForm
                            value = {state.search}
                            name = {"search-button"}
                            label = "Search"
                            required = {false}
                            readOnly = {false}
                            onChange = {handleSearch}
                            autoFocus = {state.count > 0} // This is necessary to make sure that the form automatically reselects once the grid is rerendered. Otherwise it would unselect.
                        />
                    </Box>
                    <Box pr = {2}>
                        <GridToolbarExport/>
                    </Box>
                </Box>
            </GridToolbarContainer>
        )
    }

    // const RowDetail = ({row}) => (
    //     <Details
    //         name = {row.name}
    //         project = {row.project}
    //     />
    // )

    // const [state, setState] = useState({search: '', count: 0, filters: []});

    // const handleFilters = useCallback((vals) => {
    //     console.log(vals);
    //     setState(Object.assign({}, state, {filters: formatFilters(vals)}));
    // }, [state, setState]);

    return (
        <Container>
            <Paper>
                <Box style={{ height: 520, width: '100%' }}>
                    <DataGrid
                        rows = {state.list}
                        columns = {columns}
                        rowHeight = {45}
                        pagination = {true}
                        pageSize = {100}
                        components = {{ Toolbar: Toolbar}}
                        rowsPerPageOptions = {[5, 10, 25, 50]}
                    />
                </Box>
            </Paper>
            <Dialog open={dialog.open} onClose={() => handleCloseDialog()} aria-labelledby="details-dialog" maxWidth="md">
                <DialogTitle id="details-dialog-title">{"Result Details"}</DialogTitle>
                <DialogContent>
                    <Details
                        project = {dialog.data}
                    />
                </DialogContent>
                <DialogActions>
                    <Button component="span" className={classes.button} onClick = {() => handleCloseDialog()}>
                    Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

